<!--
 * @Description: 政策事项进驻情况
 * @Author: LXG
 * @Date: 2021-06-10
 * @Editors: LXG
 * @LastEditTime: 2021-06-10
-->
<template>
    <div class="policy-matter-presence">
        <div
            class="pad-tb-5 wrap-header"
            :style="{'background-image':`url(${require('@/assets/images/policyConfigBillboard/efficacy-stat.png')})`}"
        >
            <div class="def-container">
                <h1 class="title">政策配置看板</h1>
                <div class="pad-rl-2 overview">
                    <p>
                        政策数字化情况
                        <el-tooltip
                            class="item"
                            effect="light"
                            content="政策数字化配置、发布总览"
                            placement="bottom"
                        >
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </p>
                    <div class="pad-tb-4 overview-total">
                        <div class="overview-total-item">
                            <p class="total-value">5</p>
                            <p class="total-label">政策文件</p>
                        </div>
                        <div class="overview-total-item">
                            <p class="total-value">66</p>
                            <p class="total-label">政策项目</p>
                        </div>
                    </div>
                    <div class="overview_footer">
                        截止：2021年6月10日
                    </div>
                </div>
            </div>
        </div>
        <div class="def-container wrap-content">
            <ul class="tabbar">
                <li
                    v-for="(item,index) in tabInfo.list"
                    :key="index"
                    class="marg-rl-1 tab-item"
                    :class="{'tab--active':index===tabInfo.active}"
                    @click="changeTab(item,index)"
                >
                    {{item.label}}
                </li>
            </ul>
            <div v-show="tabInfo.active==0">
                <el-table
                    class="list-table"
                    :data="listData"
                >
                    <el-table-column
                        fixed="left"
                        type="index"
                        label="序号"
                        width="55px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        fixed="left"
                        prop="name"
                        label="政策项目"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="base"
                        label="基本信息"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="czgc"
                        label="操作规程"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="pzcl"
                        label="配置材料"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="dzfb"
                        label="订制范本"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="zzbz"
                        label="资助标准"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="pzbd"
                        label="配置表单"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="pzlc"
                        label="配置流程"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="wwsb"
                        label="外网申报"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="spbl"
                        label="审批办理"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        prop="fbzt"
                        label="发布状态"
                        min-width="120px"
                        align="center"
                    ></el-table-column>
                </el-table>
                <div class="pagination">
                    <div class="pagination-elevator">
                        <FormInput
                            v-model.number="pageInfo.num"
                            size="small"
                            placeholder=""
                        ></FormInput>
                        <div>/ {{Math.ceil(pageInfo.total/pageInfo.size)}}</div>
                        <div class="pad-rl-1 elevator-btn">跳转</div>
                    </div>
                    <div class="pagination-next">></div>
                </div>
                <p>
                    <span>说明：</span>
                    统计数据只包含行政许可事项，不含公共服务事项和国家垂直系统事项。
                </p>
            </div>
            <div v-show="tabInfo.active==1">
                <div class="marg-tb-2 empty">
                    <img :src="require('@/assets/images/policyConfigBillboard/matters-empty.png')" />
                    <p>没有找到数据</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormInput } from '@/components/form/index.js'

export default {
    name: 'PolicyMatterPresence',
    components: {
        FormInput,
    },
    data() {
        return {
            tabInfo: {
                active: 0,
                list: [
                    { value: '0', label: '政策配置情况' },
                    //{ value: '1', label: '空页面示例' },
                ],
            },
            listData: [],
            pageInfo: {
                num: 1,
                oldnum: 1,
                size: 10,
                total: 10,
            },
        }
    },
    methods: {
        /**
         * @description: 切换tab
         * @param {Object} item tab
         * @param {Number} index 下标
         */
        changeTab(item, index) {
            this.tabInfo.active = index
        },
    },
    created() {
        for (let i = 0; i < 10; i++) {
            this.listData.push({
                name: '越秀区',
                base: '31',
                czgc: '31',
                pzcl: '31',
                dzfb: '31',
                zzbz: '100%',
                pzbd: '31',
                pzlc: '31',
                wwsb: '31',
                spbl: '31',
                fbzt: '31',
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.wrap-header {
    margin-bottom: 40px;
    background-color: #09294d;
    background-position: center bottom;
    background-repeat: no-repeat;
}
.title {
    margin-bottom: 40px;
    line-height: 1;
    font-size: 2rem;
    color: #ffffff;
}
.overview {
    width: 18rem;
    padding-top: 10px;
    color: #ffffff;
    background-color: #233e5f;

    .overview-total {
        display: -webkit-flex;
        display: flex;
    }
    .overview-total-item {
        flex: 1;
        text-align: center;

        .total-value {
            font-size: 2rem;
        }
        .total-label {
            color: #9ea9b6;
        }
    }
    .overview_footer {
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid #38506f;
        text-align: center;
        color: #9ea9b6;
    }
}
.wrap-content {
    margin-bottom: 40px;
}
.tabbar {
    border-bottom: 4px solid #000000;
    margin-bottom: 20px;
    font-size: 1.2rem;
    font-weight: 700;
    color: #000000;

    .tab-item {
        position: relative;
        display: inline-block;
        line-height: 2.5;
        cursor: pointer;

        &:hover {
            color: #4293f4;
        }

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &.tab--active {
            color: #4293f4;

            &::after {
                content: "";
                position: absolute;
                bottom: -4px;
                left: 0;
                display: block;
                width: 100%;
                height: 8px;
                background-color: #4293f4;
            }
        }
    }
}
::v-deep .list-table {
    margin-bottom: 20px;

    .el-table__header th {
        font-weight: normal;
    }
    .el-table__body td {
        color: #000000;
    }
}
.pagination {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    line-height: 1;

    .pagination-elevator {
        display: -webkit-flex;
        display: flex;
        align-items: center;

        ::v-deep .form-input {
            width: 50px;
            margin-right: 10px;

            .el-input__inner {
                text-align: center;
            }
        }

        .elevator-btn {
            display: inline-block;
            height: 32px;
            margin-left: 10px;
            line-height: 32px;
            text-align: center;
            color: #4293f4;
            background-color: rgba(#4293f4, 0.2);
            cursor: pointer;

            &:hover {
                color: #ffffff;
                background-color: rgba(#4293f4, 0.8);
            }
        }
    }
    .pagination-next {
        width: 32px;
        height: 32px;
        margin-left: 10px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        background-color: #4293f4;
        cursor: pointer;

        &:hover {
            background-color: rgba(#4293f4, 0.8);
        }
    }
}
.empty {
    line-height: 2;
    text-align: center;

    img {
        height: 10rem;
    }
}
</style>